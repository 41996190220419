import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Trans from "next-translate/Trans";
import { Button, Icon } from "../common";
import { lighten } from "polished";
import publicRuntimeConfig from "../../lib/public-runtime-config";
import { useIsMobile } from "../../lib/media";
import { Flex, Text } from "rebass/styled-components";

interface Props {
  text?: string;
  callback: (response: any) => void;
}

const FacebookButton = ({ callback, text }: Props) => {
  const isMobile = useIsMobile();
  return (
    <FacebookLogin
      appId={publicRuntimeConfig.facebookAppId}
      fields="email"
      version="5.0"
      callback={response => callback(response)}
      isMobile={isMobile}
      disableMobileRedirect={true}
      render={renderProps => (
        <Button
          type="button"
          size="large"
          width={1}
          sx={{
            backgroundColor: "#6282C5",
            ":hover": { backgroundColor: lighten(0.05, "#6282C5") },
            cursor: "pointer"
          }}
          onClick={e => {
            e.preventDefault();
            renderProps.onClick();
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Icon name="facebook" color="samoyedWhite" />
            <Text as="span" flexGrow={1}>
              <Trans i18nKey={text}/>
            </Text>
          </Flex>
        </Button>
      )}
    />
  );
};

export default FacebookButton;
