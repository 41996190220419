import React, { useState } from "react";
import useTranslation from "next-translate/useTranslation";

import { useIsMobile } from "../../lib/media";
import { Text, Box, Flex } from "rebass/styled-components";
import { Modal, Button, Input, Heading, Icon, ErrorProp } from "../common";
import { Label } from "@rebass/forms/styled-components";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { FullLoader } from "../common/loader";
import flash from "../../lib/flash";
import { pushRoute } from "../../lib/routing";
import { validateEmail } from "../../lib/utils";

interface Props {
  email: string;
  onChange: (email) => void;
  error: ErrorProp;
}

interface ForgotPasswordModalProps {
  onClose: () => void;
}
const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      errors {
        message
        path
      }
    }
  }
`;

const ForgotPasswordMain = ({ email, onChange, error }: Props) => {
  const { t } = useTranslation("common");
  return (
    <Box width={1}>
      <Heading as="h3">{t("resetPassword")}</Heading>
      <Heading as="h4" mt={2}>
        {t("forgotPasswordDesc")}
      </Heading>
      <Box variant={error.hasError && "error"}>
        <Label flexWrap="wrap" mt={2}>
          <Text width={1} mb={1}>
            {t("email")}
          </Text>
          <Box width={1}>
            <Input
              name="email"
              type="email"
              value={email}
              iconRight="mail"
              placeholder={t("email")}
              onChange={({ target }) => {
                onChange(target.value);
              }}
              data-test="email"
            />
          </Box>
          {error.hasError && <Text as="span">{`${error.message}`}</Text>}
        </Label>
      </Box>
    </Box>
  );
};

const ForgotPasswordModal = ({ onClose }: ForgotPasswordModalProps) => {
  const { t } = useTranslation("common");
  const isMobileViewport = useIsMobile();

  const [state, setState] = useState({
    email: "",
    error: {
      hasError: false,
      message: ""
    }
  });

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      onClose();
      flash.set("forgotPasswordEmail", state.email);
      pushRoute({ pathname: "/" });
    }
  });

  const onChange = email => {
    setState({
      email: email,
      error: {
        hasError: false,
        message: ""
      }
    });
  };

  const onForgotPassword = () => {
    if (!validateEmail(state.email.trim())) {
      setState({
        ...state,
        error: {
          hasError: true,
          message: t("login:wrongFormat")
        }
      });
    } else {
      forgotPassword({
        variables: {
          email: state.email
        }
      });
    }
  };

  const ModalFooter = () => (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "space-between"
      }}
      width={1}
    >
      <Flex
        onClick={onClose}
        sx={{
          color: "springGreen",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <Icon name="arrow-left" fontSize={2} color="springGreen" />
        <Text pl={1}>{t("backToLogin")}</Text>
      </Flex>
      <Button variant="secondary" onClick={onForgotPassword}>
        {t("sendResetLink")}
      </Button>
    </Flex>
  );

  return isMobileViewport ? (
    <Modal
      open
      size="full"
      FooterButton={<ModalFooter />}
      HeaderButton={<Box></Box>}
      onClose={onClose}
    >
      <Box py={4}>
        {loading && <FullLoader />}
        <ForgotPasswordMain
          email={state.email}
          onChange={onChange}
          error={state.error}
        />
      </Box>
    </Modal>
  ) : (
    <Modal open onOutsideClick={onClose} onClose={onClose}>
      <Box px={5} py={1}>
        <Box mt={3}>
          {loading && <FullLoader />}
          <ForgotPasswordMain
            email={state.email}
            onChange={onChange}
            error={state.error}
          />
          <Box py={3}>
            <ModalFooter />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const ForgotPassword = () => {
  const [state, setState] = useState(false);
  const { t } = useTranslation("common");

  return (
    <>
      <Text
        as="p"
        color="springGreen"
        textAlign="center"
        sx={{
          cursor: "pointer",
          fontSize: 3
        }}
        onClick={() => {
          setState(true);
        }}
      >
        {t("forgotPassword")}
      </Text>
      {state && (
        <ForgotPasswordModal
          onClose={() => {
            setState(false);
          }}
        />
      )}
    </>
  );
};

export default ForgotPassword;
