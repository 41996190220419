import { useEffect } from "react";
import { Button, Icon } from "../common";
import { Flex, Text } from "rebass/styled-components";
import publicRuntimeConfig from "../../lib/public-runtime-config";

/**
 * the response comes in the following format,
 * with the user object only present on the first time the user authenticates with apple:
 * */
export interface AppleAuth {
  authorization: {
    code: string;
    id_token: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (x: any) => void;
        signIn: () => AppleAuth;
      };
    };
  }
}

interface Props {
  text?: string;
  callback: (response: AppleAuth) => void;
}

// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
const AppleButton = ({ callback, text }: Props) => {
  const { appleAuthClientId } = publicRuntimeConfig;

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/es_ES/appleid.auth.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  const authenticate = async e => {
    e.preventDefault();
    window.AppleID.auth.init({
      clientId: appleAuthClientId,
      scope: "name email",
      redirectURI: `${window.location.origin}${window.location.pathname}`,
      usePopup: true
    });

    const data: AppleAuth = await window.AppleID.auth.signIn();

    callback(data);
  };

  return (
    <Button
      width={1}
      size="large"
      type="button"
      variant="terciary"
      sx={{
        fontWeight: "book",
        cursor: "pointer"
      }}
      onClick={authenticate}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Icon name="apple" />
        <Text as="span" flexGrow={1}>
          {text}
        </Text>
      </Flex>
    </Button>
  );
};

export default AppleButton;
