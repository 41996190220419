import { Input } from "../common";
import { Box } from "rebass/styled-components";
import { useState } from "react";
import { useIsMobile } from "../../lib/media";

const inputState = {
  active: { icon: "eye-open", type: "text" },
  inactive: { icon: "eye-closed", type: "password" }
};

interface Props {
  text: string;
  [x: string]: any;
}

const PasswordInput = ({ text, ...rest }: Props) => {
  const [state, setState] = useState(inputState.inactive);
  const isMobile = useIsMobile();
  return (
    <Box width={1} sx={{ position: "relative" }}>
      <Input
        type={state.type}
        iconRight={state.icon}
        placeholder={text}
        {...rest}
      />
      <Box
        sx={{
          position: "absolute",
          cursor: "pointer",
          top: [0, 0, 2],
          right: [0, 0, 2],
          bottom: [0, 0, 2],
          padding: [3, 3, 1],
          userSelect: "none"
        }}
        onMouseDown={() => {
          if (state.type == "password") {
            setState(inputState.active);
          } else {
            setState(inputState.inactive);
          }
        }}
        onMouseUp={() => {
          !isMobile ? setState(inputState.inactive) : null;
        }}
        onMouseLeave={() => {
          !isMobile ? setState(inputState.inactive) : null;
        }}
      />
    </Box>
  );
};

export default PasswordInput;
