
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Flex, Box } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";

import Layout from "../components/Layout";
import LoginForm from "../components/login/LoginForm";
import { withoutAuth } from "../lib/auth";

interface Props {
  query: Record<string, any>;
}

function Login({ query }: Props) {
  const { t } = useTranslation("login");

  return (
    <Layout withTransparentHeader immersive title={t("login:title")}>
      <Flex alignItems="center" height={["auto", "100vh"]} variant="dogHair">
        <Box variant="layout.small">
          <LoginForm query={query} />
        </Box>
      </Flex>
    </Layout>
  );
}
 const _getServerSideProps = withoutAuth()(async ({ query }) => {
  return { props: { query } };
});

export default Login;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  